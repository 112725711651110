import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { Toast } from 'vant';
import { getToken } from '@/utils/auth';
import qs from 'qs';
import md5 from './md5';
import { showFullScreenLoading, tryHideFullScreenLoading } from './loading';

Vue.use(Toast);
let ErrorToast = null;
function showErrorToast(msg) {
  if (ErrorToast) {
    ErrorToast.clear();
  }
  ErrorToast = Toast({
    position: 'top',
    message: msg || 'Error',
  });
}

function jsonSort(jsonObj) {
  if (!jsonObj) return '';
  const arr = [];
  Object.keys(jsonObj).map((key) => arr.push(key));
  arr.sort();
  let str = '';
  arr.forEach((item) => {
    if (jsonObj[item] !== '' && jsonObj[item] != null) {
      str += `${item}=${jsonObj[item]}&`;
    }
  });
  return str;
}

function headerData(param) {
  const data = {};
  const token = getToken();
  data.nonce = Math.floor(Math.random() * 1000000).toFixed(0);
  data.timestamp = +new Date();
  const { key } = store.getters;
  const signStr = jsonSort(param) + key + token + data.timestamp + data.nonce;
  data.sign = md5(signStr);
  return data;
}

// create an axios instance
const Axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 40000, // request timeout
});

// request interceptor
Axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // do something before request is sent
    // eslint-disable-next-line no-param-reassign
    config.data = config.data || {};
    const { lang } = store.getters;
    Object.assign(config.data, { lang });
    if (store.getters.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.token = store.getters.token;
      const { params, data } = config;
      // eslint-disable-next-line no-param-reassign
      Object.assign(config.headers, { ...headerData({ ...params, ...data }) });
    }
    // eslint-disable-next-line no-param-reassign
    config.data = qs.stringify(config.data);
    // show loading
    if (store.getters.axiosLoading) {
      showFullScreenLoading();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
Axios.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    if (store.getters.axiosLoading) {
      tryHideFullScreenLoading();
    }
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      if (res.code === 404002) {
        store.dispatch('user/resetToken').then(() => {
          const { notReload } = response.config;
          if (notReload) {
            // 避免没有token死循环
            router.push({ name: 'Login' });
          } else {
            // eslint-disable-next-line no-restricted-globals
            // location.reload();
            router.push({ name: 'Login' });
          }
        });
      }
      res.code !== 404002 && showErrorToast(res.msg);
      return Promise.reject(new Error(res.message || 'Error'));
    }
    return res;
  },
  (error) => {
    if (store.getters.axiosLoading) {
      tryHideFullScreenLoading();
    }
    showErrorToast(error.message);
    console.log(`err${error}`); // for debug
    return Promise.reject(error);
  },
);

// eslint-disable-next-line
function service(options) {
  if (options.loading) {
    store.dispatch('config/changeLoading', true);
  }
  return Axios(options);
}

export default service;
