/* eslint-disable */
const state = {
  pageRoute: {},
};
const mutations = {
  SET_PAGE_ROUTE: (state, pageRoute) => {
    state.pageRoute = pageRoute;
  },
};
const actions = {
  changePageRoute({ commit }, pageRoute) {
    commit('SET_PAGE_ROUTE', pageRoute);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
