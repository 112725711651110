/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import Cookies from 'js-cookie';

const TokenKey = 'NFToken';

// export function getToken() {
//   return Cookies.get(TokenKey);
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token);
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey);
// }

export function setToken(token) {
  window.localStorage[TokenKey] = token;
}

export function getToken() {
  return window.localStorage[TokenKey];
}

export function removeToken() {
  window.localStorage.removeItem(TokenKey);
}
